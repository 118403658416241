import housingImages from './get-housing-image-list';

export default function () {
  'use strict';

  if (!$('.productTable').length) {
    return;
  }

  var thumbnail_div = $('<div id="housing_thumbnail"><img alt="Housing Thumbnail"/><p></p></div>');
  $('body').append(thumbnail_div);
  thumbnail_div.hide();

  var imageList = function () { };

  var preloadImages = function () {
    housingImages().map(function (el) {
      return (new Image().src = el);
    });
  };
  preloadImages();

  $('.productLinks a:first-of-type').hover(
    function () {
      var position = $(this).offset();
      var thumb_top = position.top + $(this).height() + 10;
      var thumb_left = position.left - 3;
      var thumb_width =
        $(this)
          .parent()
          .parent()
          .innerWidth() - 14;
      var dimensions =
        $(this).data('length') +
        ' &nbsp;x&nbsp; ' +
        $(this).data('width') +
        ' &nbsp;x&nbsp; ' +
        $(this).data('height');
      thumbnail_div.css('top', thumb_top);
      thumbnail_div.css('left', thumb_left);
      thumbnail_div.css('width', thumb_width);
      $('img', thumbnail_div).attr('src', $(this).data('file'));
      $('p', thumbnail_div).html(dimensions);
      thumbnail_div.show();
    },
    function () {
      thumbnail_div.hide();
    }
  );
};
