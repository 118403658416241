$(document).on('turbolinks:load', function() {
  var outline = location.pathname.split('/').pop();
  if (!outline || outline == 'outlines') {
    outline = '301';
  }

  var select_str = '.outlineList select';
  var select = $(select_str);
  select.val(outline);

  $(document).on('change', select_str, function() {
    var outline = $(this).val();
    Turbolinks.visit('/outlines/' + outline);
  });
});
