// Scrapes product links for data-file tags and returns as an array.

export default function () {
  return $('.productLinks a')
    .map(function (el) {
      return $(this).data('file');
    })
    .toArray()
    .filter(function (e, i, arr) {
      return arr.indexOf(e) === i;
    });
};
