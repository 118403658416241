$.fn.DataTable.ext.type.order['frequency-pre'] = function(data) {
  var matches = data.match(/^(\d+(?:\.\d+)?)\s*([a-z]+)/i);
  var multipliers = {
    khz: 0.001,
    mhz: 1,
    ghz: 1000,
  };

  if (matches) {
    var multiplier = multipliers[matches[2].toLowerCase()];
    return parseFloat(matches[1]) * multiplier;
  } else {
    return parseFloat(data);
  }
};
