export default function () {
  var tabContentPairs = {};
  var that = this;
  var activeTabID = null;

  this.addPair = function (tabID, contentID) {
    tabContentPairs[tabID] = contentID;
    $(tabID).on('click.tab', function (e) {
      e.preventDefault();
      that.setActiveTab(tabID);
    });
  };

  this.setActiveTab = function (tabID) {
    that.activeTabID = that.activeTabID || tabID;
    $(tabID).addClass('active');
    $(tabContentPairs[tabID]).show();
    for (var tab in tabContentPairs) {
      if (tab != tabID) {
        $(tab).removeClass('active');
        $(tabContentPairs[tab]).hide();
      }
    }
  };

  this.resetActiveTab = function () {
    that.setActiveTab(that.activeTabID);
  };

  this.tabPairs = function () {
    return tabContentPairs;
  };

  this.resetTabs = function () {
    that.resetActiveTab();
    $.each(tabContentPairs, function (tabID) {
      $(tabID).off('click.tab');
    });
  };
};
