// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
// console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'
import Turbolinks from 'turbolinks';
Turbolinks.start();
import RailsUJS from '@rails/ujs';
RailsUJS.start();
window.$ = $; // For RailsUJS / .js.erb files

import '../jquery.autogrow-textarea.js';
import '../jquery.smartresize.js';
import '../jquery.fixedtableheader-1.0.4';
import '../jquery.simpleslideshow.js';
import '../squeezetext';

// Page-specific requires
import '../outlines.js';
import '../news_articles.js';

import DataTable from "datatables.net-dt";
import '../jquery.datatables.sorting.frequency';

import searchBar from '../search';
import mobileMenu from '../mobile-menu';
import TabController from '../tab-controller';
import { init as resizeTableInit, resize as resizeTable } from '../table-resize';
import setHousingThumbnails from '../housing-thumbnail';
import { scrollToLastPosition as buttonNavScrollToLastPosition, attachListeners as buttonNavAttachListeners } from '../button-navigation-scroll-lock';
import onResize from '../onResize';
import housingImages from '../get-housing-image-list';


// Load charts related javascript for product pages that have the tab
$('li.performance').click(function() {
  $.getScript("/plotly-basic.min.js", function() {
    $.getScript("/charts.js");
  });
})

// For use in workarounds due to iPhone 6+ landscape / fixed position bug
// http://www.openradar.me/19410956
var isIPhone6SPlusLandscape = function() {
  return $(window).width() == 736 && $(window).height() == 414;
};

function printPDF(url) {
  var iframe = this._printIframe;
  if (!this._printIframe) {
    iframe = this._printIframe = document.createElement('iframe');
    document.body.appendChild(iframe);

    iframe.style.display = 'none';
    iframe.onload = function() {
      setTimeout(function() {
        iframe.focus();
        iframe.contentWindow.print();
      }, 1);
    };
  }

  iframe.src = url;
}

function setStickyHorizontalNav(start_width) {
  var stickyNav = $('#horz_nav.sticky');
  if (stickyNav.html().length > 0) return;

  $('#horz_nav.sticky').html($('#horz_nav').html());

  var belowHeader = function() {
    return $(this).scrollTop() > $('header').height() + 5 && $(window).width() > start_width;
  };

  if (belowHeader()) {
    stickyNav.css('display', 'block');
  }

  $(window).on('scroll.stickyNav', function() {
    var $nav = $('#horz_nav.sticky');
    if (belowHeader()) {
      $nav.css('display', 'block');
    } else {
      $nav.css('display', 'none');
    }
  });
}

function shuffle(array) {
  var i = 0,
    j = 0,
    temp = null;

  for (i = array.length - 1; i > 0; i -= 1) {
    j = Math.floor(Math.random() * (i + 1));
    temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}

function setHousingCarousel() {
  var section_height =
    $('.section_header .section_headline').outerHeight() + $('.page_summary').outerHeight() + 50;
  section_height = section_height < 180 ? section_height + 'px' : '180px';
  $('#carousel_wrapper').prepend(
    '<style>#carousel img { height: ' + section_height + '; }</style>'
  );
  var images = housingImages().map(function(path) {
    return '<img src="' + path + '" alt=""/>';
  });
  shuffle(images);
  var firstImage = $('<div class="current-slide">' + images.shift() + '</div>');
  $('#carousel').append(firstImage);
  firstImage.addClass('current-slide');
  if (images.length) {
    // VITE BROKE
    // $('#carousel').simpleSlideshow({
    // slideshowData: images,
    // slidePauseSpeed: 3500, // the amount of time (in ms) to show the slide for
    // paging: false, // show paging controls
    // });
  }
}

var productTabController = new TabController();
var generalTabController = new TabController();

// Fix for turbolinks anchor tag bug
const linkTargetsAnchorOnSamePage = function(link) {
  const href = link.getAttribute('href');

  if (href.charAt(0) === '#') {
    return true;
  }

  if (href.match(new RegExp('^' + window.location.toString().replace(/#.*/, '') + '#'))) {
    return true;
  } else if (href.match(new RegExp('^' + window.location.pathname + '#'))) {
    return true;
  }

  return false;
};
$(document).on('turbolinks:click', function(event) {
  if (linkTargetsAnchorOnSamePage(event.target)) {
    return event.preventDefault();
  }
});

// $(document).on('turbolinks:load', function(event) {
//   if (window.location.hash) {
//     const $element = $(`a[name="${window.location.hash.substring(1)}"]`);
//     return $('html, body').scrollTop($element.offset().top);
//   }
// });

function setFixedTableHeader() {
  $('.productTable').each(function(i) {
    $(this).fixedtableheader({
      headerrowsize: $(this)
        .find('tr')
        .has('th').length,
      highlightrow: true,
      highlightclass: 'ruled',
      tableNumber: i,
    });
  });
}

var tables = null;
var productTabController = new TabController();

document.addEventListener('turbolinks:before-cache', function() {
  $("[id^='fixedtableheader']").remove();
  $('.cartContents').remove();
  $('#housing_thumbnail').remove();
  $('#carousel').empty();
  productTabController.resetActiveTab();
  if (tables !== null) {
    tables.destroy();
    tables = null;
  }
});

document.addEventListener('turbolinks:render', function() {
  buttonNavScrollToLastPosition();
});

document.addEventListener('turbolinks:load', function() {
  var mobile_width = 769;
  var isProductPage = $('#product_page').length ? true : false;
  var isRFQPage = $('#rfq_page').length ? true : false;

  if (isRFQPage) {
    $('#quote_request_item_note').autogrow();
    $('.notice_box')
      .hide()
      .slideDown(700);
  }

  if (!isProductPage) {
    var freqMaxColumnList = {
      '/products/hybrids/hybrids': 2,
      '/products/hybrids/90deg_21-41': 2,
      '/products/hybrids/90deg_21-41_stripline': 2,
      '/products/hybrids/90deg_51-1251': 2,
    };
    var freqMaxColumn = 1;
    if (window.location.pathname in freqMaxColumnList) {
      freqMaxColumn = freqMaxColumnList[window.location.pathname];
    }

    tables = $('.productTable').DataTable({
      paging: false,
      // order: [], // No ordering applied by DataTables during initialisation */,
      order: [[freqMaxColumn, 'asc']],
      searching: false,
      autoWidth: false,
      columnDefs: [
        {
          type: 'frequency',
          targets: [freqMaxColumn - 1, freqMaxColumn],
        },
      ],
      infoCallback: function(settings, start, end, max, total, pre) {
        var info = 'Showing ' + end + ' matches (filtered from ' + max + ' total)';
        if (end != max) {
          return info;
        }
      },
      fnDrawCallback: function(settings, json) {
        setFixedTableHeader();
      },
    });
    resizeTableInit('.productTable', 828); // Value must match main computed width at full browser width
    resizeTable();
    setFixedTableHeader();

    // Dynamically Resize Table
    // window.lastWidth check is for an iOS 10 bug that triggers resize on scroll
    window.lastWidth = null;
    // $(window).smartresize(function() {
    $(window).on('resize', function() {
      if (window.lastWidth != $(window).width()) {
        resizeTable();
        setFixedTableHeader();
        window.lastWidth = $(window).width();
      }
    });
  }

  setStickyHorizontalNav(mobile_width);
  mobileMenu(mobile_width);
  searchBar();
  buttonNavAttachListeners();

  // Prelim code for fixing responsive multirow/col headers
  // https://github.com/DataTables/Responsive/issues/71
  // table.on('responsive-resize', function(e, datatable, columns) {
  //   columns.forEach(function(is_visible, index) {
  //     $.each($('tr', datatable.table().header()), function() {
  //       var col = $($(this).children()[index]);
  //       is_visible == true ? col.show() : col.hide();
  //     });
  //   });
  // });

  // if (!isProductPage) {
  // Add fixed table header
  // $('.productTable').each(function(i) {
  // $(this).DataTable();
  // $(this).fixedtableheader({
  //   headerrowsize: $(this)
  //     .find('tr')
  //     .has('th').length,
  //   highlightrow: true,
  //   highlightclass: 'ruled',
  //   heightoffset: 45,
  //   tableNumber: i,
  // });
  // });

  // Dynamically Resize Table
  // resizeTable('.productTable', 695);
  // $(window).smartresize(function() {
  //   resizeTable('.productTable', 695);
  //   $('table[id^=fixedtableheader]').css('width', $('.productTable').width() + 2);
  // });
  // }

  // Workaround for iPhone 6+ landscape / fixed position bug
  // http://www.openradar.me/19410956
  var iPhone6SPlusWorkaround = function() {
    if (isIPhone6SPlusLandscape()) {
      $('#mobile_header').css('position', 'relative');
    } else {
      $('#mobile_header').css('position', 'fixed');
    }
  };

  iPhone6SPlusWorkaround();
  onResize(function() {
    iPhone6SPlusWorkaround();
  });

  if (isProductPage) {
    productTabController.resetTabs();
    productTabController.addPair('.specs', '#specs');
    productTabController.addPair('.datasheet', '#datasheet');
    productTabController.addPair('.performance', '#performance');
    productTabController.addPair('.support', '#support');
    productTabController.setActiveTab('.specs');
    if ($(window).width() <= mobile_width) {
      // Open pdf in same window for mobile devices.
      if ($('a.pdf').attr('target')) {
        $('a.pdf').attr('target', '');
      }
    }
  }

  generalTabController.resetTabs();
  if ($('.tabs-generic').length) {
    generalTabController.addPair('.tab-1', '.tab_content-1');
    generalTabController.addPair('.tab-2', '.tab_content-2');
    generalTabController.setActiveTab('.tab-1');
  }

  var elements = document.querySelectorAll('main h2:first-of-type, .section_headline');
  window.squeezeText.elements = [];
  window.squeezeText(elements);

  if ($(window).width() > mobile_width) {
    setHousingCarousel();
    setHousingThumbnails();
  }
});
