export const scrollToLastPosition = function () {
  var scrollTo = sessionStorage.getItem('scrollTo');
  if (scrollTo) {
    window.scrollTo(0, scrollTo);
    sessionStorage.removeItem('scrollTo');
  }
};

export const attachListeners = function () {
  var nav_buttons = document.getElementsByClassName('nav_buttons');
  if (nav_buttons.length > 0) {
    var nav_links = nav_buttons[0].getElementsByTagName('a');
    for (var i = 0; i < nav_links.length; i++) {
      nav_links[i].addEventListener('click', function () {
        sessionStorage.setItem(
          'scrollTo',
          document.documentElement.scrollTop || document.body.scrollTop
        );
      });
    }
  }
};
