
$(document).ready(function() {
  var select = $('#newproducthead select');
  var category = location.pathname.split('/').pop();
  select.val(category).change();

  $(document).on('change', '#newproducthead select', function(){
    var category = $(this).val();
    if (category) {
      window.location.href = '/new-products/' + category;
    } else {
      window.location.href = '/';
    }
  });
})
