import './jquery.hidden-dimension';

var tables;
var tableObjects = [];

export const init = function (selector, table_max_width_px) {
  // table_max_width_px should probably be moved to CSS
  tables = $(selector);
  $.each(tables, function (i, table) {
    tableObjects[i] = {};
    var t = tableObjects[i];
    var $table = $(table);
    tableObjects[i].col_dimensions = [];
    $table.css('width', table_max_width_px + 'px');
    $table.find('th').show();
    $table.find('td').show();

    // var widest_a = 0;
    // $table.find('a').each(function(i, el) {
    //   var w = $(el).outerWidth();

    //   // console.log(el.innerHTML.length);
    //   widest_a = w > widest_a ? w : widest_a;
    // });

    // var form_width = $($table.find('form')[0]).outerWidth();
    // $table.find('thead tr:first th:last').css('width', widest_a + form_width + 'px');
    // $table.find('thead tr:first th:last').css('background-color', 'black');

    // var table_clone = $table.clone();
    // table_clone.css('width', table_max_width_px);
    // table_clone.css('visibility', 'hidden');
    // table_clone.appendTo('body');
    // var table_clone_ths = table_clone.find('thead tr:first th');
    var table_ths = $table.find('thead tr:first th');
    var col_widths = [];
    table_ths.each(function (i, col) {
      var cur_col = {};
      cur_col.index = i;
      cur_col.pixel_width = $(col).outerWidth();
      t.col_dimensions.push(cur_col);
    });
    $table.css('width', '100%');
    // table_clone.remove();
  });
}

export const resize = function () {
  $.each(tables, function (i, table) {
    var t = tableObjects[i];
    var $table = $(table);
    var table_parent = $table.parent();
    var ths;
    var container; //thead or tbody
    container = 'thead';

    ths = $table.find('thead tr:first th');

    var col_pixel_total = 0;
    $.each(t.col_dimensions, function (i, col) {
      col_pixel_total += col.pixel_width;
    });

    var tot_col = 0;
    $(ths).each(function (i, th) {
      t.col_dimensions[i].colspan = parseInt($(th).attr('colspan')) || 1;
      tot_col += t.col_dimensions[i].colspan;
    });

    var showAll = function () {
      $.each(ths, function (i, th) {
        $(th).css('width', t.col_dimensions[i].pixel_width + 'px');
      });
      $.each($table.find(container + ' th'), function (i, th) {
        $(th).show();
      });
      $.each($table.find('td'), function (i, td) {
        $(td).show();
      });
      $table.find('tr td:only-child').attr('colspan', tot_col);
    };

    showAll();

    /*
       * Column removal
       * Columns are removed from right to left, skipping the rightmost column.
       * The colspan attribute screws up what would otherwise be an easier task.
       *
       */
    var th_index = t.col_dimensions.length - 3; // current th to remove
    while (col_pixel_total > table_parent.hiddenDimension('width') && th_index > 0) {
      // Calculate the maximum possible td index to check, taking colspans into consideration
      var td_index = 0;
      for (var i = 0; i < th_index; i++) {
        td_index += parseInt($(ths[i]).attr('colspan')) || 1;
      }
      var th_colspan = parseInt($(ths[th_index]).attr('colspan')) || 1;
      // Hide any subheading TH's
      if (th_colspan > 1) {
        var offset = 0;
        for (var i = 0; i < th_index; i++) {
          if (t.col_dimensions[i].colspan > 1) {
            offset += t.col_dimensions[i].colspan;
          }
        }
        offset -= 1;
        if (offset <= 0) {
          $table.find(container + ' tr:gt(0) th').hide();
        }
        if (offset > 0) {
          $table.find(container + ' tr:gt(0) th:gt(' + offset + ')').hide();
        }
      }
      // Process each row
      $.each($table.find('tbody tr'), function (i, tr) {
        var tds = $(tr).find('td');
        if (tds.length == 1) {
          $(tds[0]).attr('colspan', td_index + 1);
        } else {
          var remove_index_start = td_index;
          for (var i = 0; i < td_index; i++) {
            var td_colspan = parseInt($(tds[i]).attr('colspan')) || 0;
            if (td_colspan) {
              remove_index_start = remove_index_start - td_colspan + 1;
            }
          }
          for (var i = 0; i < th_colspan; i++) {
            $(tds[remove_index_start + i]).hide();
          }
        }
        $(ths[th_index]).hide();
      });
      col_pixel_total -= t.col_dimensions[th_index].pixel_width;
      th_index--;
    }
  });
}