// Wrapper for code to be run when the window is resized, or mobile screen is rotated.
export default function (f) {
  window.onResize = window.onResize || {};
  window.onResize.f_arr = window.onResize.f_arr || [];
  window.onResize.f_arr.push(f);

  if (window.onResize.lastWidth == null) {
    window.onResize.lastWidth = $(window).width();
  }
  $(window).resize(function () {
    if ($(window).width() != window.onResize.lastWidth) {
      window.onResize.lastWidth = $(window).width();
      for (var i = 0; i < window.onResize.f_arr.length; i++) {
        window.onResize.f_arr[i]();
      }
    }
  });
};
