import times from 'lodash/times';
import values from 'lodash/values';
import includes from 'lodash/includes';


var form, formInput, resultsList, body;

// TODO: mouseclick outside of form should hide results.

function init() {
  form = $('form#search');
  formInput = form.children('input');
  resultsList = form.find('#results').eq(0);
  body = $('body');

  bindUI();
  highlightResultInPage();
}

// To prevent flicker in browsers, reuse existing list elements
function sizeListElements(newSize) {
  var resultsChildren = resultsList.children();
  var diff = newSize - resultsChildren.length;
  var listElements = '';

  resultsChildren.eq(0).removeClass('no_match');

  if (diff > 0) {
    times(diff, function () {
      listElements += '<li></li>';
    });
    resultsList.append(listElements);
  } else {
    resultsChildren.slice(newSize).remove();
  }
}

function displayResults(results) {
  sizeListElements(results.length);

  if (results.length > 0) {
    var searchText = formInput.val();
    var resultsChildren = resultsList.children();
    var re = new RegExp('(' + searchText + ')', 'i');

    for (var key in results) {
      var boldedResult = results[key].name.replace(re, '<strong>$1</strong>');
      var a =
        "<a href='" +
        results[key].path +
        "'>" +
        '<span>' +
        boldedResult +
        '</span>' +
        '<span>' +
        results[key].category +
        '</span>' +
        '</a>';

      resultsChildren.eq(key).html(a);
    }
  } else {
    var li = $(
      "<li class='no_match'>" +
      'No match found: ' +
      'Part may be a custom model.<br/><br/>' +
      'Naming convention: [Series]-[Model]-[Outline]<br/><br/>' +
      'Search by series or outline for general information, or ' +
      "contact <a href='mailto:sales@pulsarmicrowave.com'>sales@pulsarmicrowave.com</a> for details on this custom model." +
      '</li>'
    );
    resultsList.append(li);
  }

  if (resultsList.length > 0) {
    resultsList.show();
  } else {
    resultsList.hide();
  }
}

function getResults() {
  return $.ajax({
    url: '/search/' + encodeURIComponent($('input').val()),
  });
}

function highlightRow(row) {
  resultsList.children().removeClass('hover');
  if (row != null) {
    row.addClass('hover');
  }
}

function highlightResultInPage() {
  if (sessionStorage.searchResult) {
    var matchedRow = $('.productTable tbody tr:contains(' + sessionStorage.searchResult + ')');
    if (matchedRow.length < 1) return;
    matchedRow.addClass('matchedSearchResult');
    sessionStorage.removeItem('searchResult');
    if (matchedRow.offset().top + 170 > $(window).height()) {
      window.scrollTo(0, matchedRow.offset().top - 170);
      matchedRow.find('a').attr("data-turbolinks", "false");
    }
  }
}

function bindUI() {
  var keyCodes = {
    ESC: 27,
    ENTER: 13,
    UP: 38,
    DOWN: 40,
  };

  var currentResult = null;
  //TODO: Move all next/prev logic into highlightRow, and have ENTER execute URL of .hover element?
  //      Note can check prev/next length in highlightRow

  body.on('click', 'input', function () {
    if (resultsList.children().length > 0) {
      resultsList.show();
    }
    body.off('click.hideSearchResults');
    body.one('click.hideSearchResults', function () {
      resultsList.hide();
    });
  });

  resultsList.on({
    mouseenter: function () {
      currentResult = $(this);
      highlightRow(currentResult);
    },
    mouseleave: function () {
      currentResult = null;
      highlightRow(null);
    },
  },
    'li'
  );

  formInput.keyup(function (e) {
    if (includes(values(keyCodes), e.keyCode)) {
      return;
    }

    if (formInput.val().length > 1) {
      getResults().done(function (data) {
        displayResults(data);
      });
    } else {
      resultsList.hide();
    }
  });

  resultsList.on('click', 'a', function (e) {
    e.preventDefault();
    sessionStorage.searchResult = $($(this).find('span')[0]).text();
    window.location.href = this.href;
  });

  formInput.keydown(function (e) {
    if (includes(values(keyCodes), e.keyCode)) {
      e.preventDefault();
    } else {
      currentResult = null;
      highlightRow(null);
    }

    switch (e.keyCode) {
      case keyCodes.ESC:
        currentResult = null;
        resultsList.hide();
        break;

      case keyCodes.ENTER:
        if (currentResult != null && currentResult.hasClass('no_match') != true) {
          sessionStorage.searchResult = $(currentResult.find('a').find('span')[0]).text();
          window.location.href = currentResult.find('a').attr('href');
        }
        break;

      case keyCodes.DOWN:
        if (currentResult == null) {
          currentResult = resultsList.children().eq(0);
        } else if (currentResult.next().length > 0) {
          currentResult = currentResult.next();
        }
        highlightRow(currentResult);
        break;

      case keyCodes.UP:
        if (currentResult != null && currentResult.prev().length > 0) {
          currentResult = currentResult.prev();
        } else {
          currentResult = null;
        }
        highlightRow(currentResult);
        break;
    }
  });
}

export default init;