import onResize from './onResize';

var mobile_menu;
var orientation; //portrait or landscape
var last_scroll_position = 0;

function reset() {
  $('main').show();
  $('footer').show();
  $(window).scrollTop(last_scroll_position);
  if (mobile_menu) {
    mobile_menu.removeClass('show');
  }
}

function getOrientation() {
  if ($(window).width() > $(window).height()) {
    return 'landscape';
  } else {
    return 'portrait';
  }
}

function init(mobile_max_width) {
  orientation = getOrientation();

  onResize(function () {
    // Close menu when shifting from mobile to full layout
    if ($(window).width() > mobile_max_width) {
      reset();
    }

    // Close menu on rotate
    if (orientation != getOrientation()) {
      orientation = getOrientation();
      reset();
    }
  });

  $('#mobile_menu_toggle').click(function () {
    if (!$('#mobileMenu').length) {
      mobile_menu = $('footer > .wrap > #container')
        .clone()
        .appendTo('body')
        .attr('id', 'mobileMenu');
    }
    if (mobile_menu.hasClass('show')) {
      //HIDE
      reset();
    } else {
      // SHOW
      last_scroll_position = $(window).scrollTop();
      $(window).scrollTop(0);
      $('main').hide();
      $('footer').hide();
      mobile_menu.addClass('show');
    }
  });
}

export default init;